<template>
    <div class="company-news-warpper">
        <div class="company-banner-warpper">
            <div class="company-banner user-content">
                <div class="info-primany flex">
                    <div class="info-message flex">
                        <div class="logo">
                            <img :src="baseInfo.logo||require('../../../assets/img/users/company_logo.png')" alt="">
                        </div>
                        <div class="info-desc">
                            <h1>{{baseInfo.companyName}} <i class="desc-icon"></i></h1>
                            <div class="info-others">{{baseInfo.companyNatureName}}<em class="dolt"></em>{{baseInfo.companyScale}}<em class="dolt"></em>{{baseInfo.companyIndustryName}}</div>
                        </div>
                    </div>
                    <div class="info-start flex">
                        <div class="start-item flex">
                            <div class="start-top">
                                <div class="bigger">{{jobTotal||0}}</div>
                                在招职位
                            </div>
                            <!-- <div class="start-bottom">
                                <i></i>完善在线简历
                            </div> -->
                        </div>
                        <div class="start-item flex">
                            <div class="start-top">
                                <div class="bigger">{{baseInfo.register}}<span style="font-size:15px;">万</span></div>
                                注册资金
                            </div>
                            <!-- <div class="start-bottom">
                                <i class="upload"></i>上传附件简历
                            </div> -->
                        </div>
                    </div>
                </div>
                <div class="conpany-tabs">
                    <div class="change-tab" :class="[$route.path==='/user/companyNews/gongsi'?'cur':'']" @click="routeTo('/user/companyNews/gongsi')">公司简介</div>
                    <div class="change-tab" :class="[$route.path==='/user/companyNews/cJobs'?'cur':'']" @click="routeTo('/user/companyNews/cJobs')">招聘职位({{jobTotal||'0'}})</div>
                </div>
            </div>
        </div>
        <router-view></router-view>
    </div>
</template>
<script>
import userRequest from '../../../api/user'
export default {
    created(){
        document.documentElement.scrollTop = 0;
        document.body.scrollTop = 0;
        this.$emit("setIndex",2)
    },
    mounted () {
        let query = this.$route.query  
        if (!query.companyId) {
            this.$router.back()
            return
        }
        this.companyId = query.companyId
        this.getCompanyDetail()
        this.jobForm.memberId = query.companyId
        this.getJobListByCompanyId()
    },
    data() {
        return {
            companyId: null,
            baseInfo: {},
            jobForm: {memberId: null, pageNum: 1, pageSize: 3},
            jobTotal: 0
        }
    },
    methods: {
        routeTo(path) {
            if (this.$route.path === path) {
                return
            }
            this.$router.push({
                path: path,
                query: {
                    companyId: this.companyId
                }
            })
        },
        getCompanyDetail() {
            userRequest.getCompanyDetail({memberId: this.companyId}).then((res) => {
                if (res.code === 200) {
                    this.baseInfo = res.data 
                }
            })
        },
        getJobListByCompanyId() {
            userRequest.getJobListByCompanyId(this.jobForm).then((res) => {
                if (res.code === 200) {
                    this.jobTotal = res.data.total
                }
            })
        }
    },
}
</script>
<style lang="scss" scoped>
    @import "../../../assets/css/global.scss";
    .company-news-warpper{
        height: 100%;
        .company-banner-warpper{
            padding-top: 20px;
            background: #444c5f;
            color: #fff;
            .company-banner{
                .info-primany{
                    justify-content: space-between;
                    .info-message{
                        width: 800px;
                        align-items: center;
                        .logo{
                            width: 103px;
                            height: 103px;
                            overflow: hidden;
                            border-radius: 13px;
                            img{
                                width: 103px;
                                height: 103px;
                            }
                        }
                        .info-desc{
                            margin-left: 25px;
                            font-size: 14px;
                            h1{
                                font-size: 32px;
                                line-height: 48px;
                                padding: 0 0 3px 0;
                                .desc-icon{
                                    display: inline-block;
                                    width: 18px;
                                    height: 18px;
                                    vertical-align: middle;
                                    margin-top: -3px;
                                    margin-left: 2px;
                                    background: url('../../../assets/img/users/icons.png') 0 -1759px;
                                }
                            }
                            .info-others{
                                line-height: 20px;
                                padding: 0;
                                color: rgba(255,255,255,.7);
                                margin-bottom: 13px;
                                .dolt{
                                    display: inline-block;
                                    vertical-align: middle;
                                    width: 2px;
                                    height: 2px;
                                    margin: 0 7px;
                                    border-radius: 50%;
                                    background-color: rgba(255,255,255,.7);
                                }
                            }
                        }
                    }
                    .info-start{
                        width: 450px;
                        margin-top: 34px;
                        .start-item{
                            flex-direction: column;
                            align-items: flex-end;
                            width: 50%;
                            .start-top{
                                color: #fff;
                                    text-align: right;
                                .bigger{
                                    text-align: right;
                                    line-height: 50px;
                                    font-size: 36px;  
                                }
                            }
                            .start-bottom{
                                margin-top: 25px;
                                color:#5ed5c8;
                                font-size: 16px;
                                i{
                                    display: inline-block;
                                    vertical-align: -3px;
                                    width: 18px;
                                    height: 15px;
                                    background: url('../../../assets/img/users/icon-resume-login.png') center center no-repeat;
                                    background-size: contain;
                                    margin-right: 8px;
                                    &.upload{
                                        background: url('../../../assets/img/users/icon-upload-login.png') center center no-repeat;  
                                    }
                                }
                            }
                        }
                    }
                }
                .conpany-tabs{
                    margin-top: 20px;
                    .change-tab{
                        display: inline-block;
                        vertical-align: top;
                        font-size: 16px;
                        color: #fff;
                        cursor: pointer;
                        margin-right: 45px;
                        padding: 13px 0 10px;
                        &.cur{
                            color: $main;
                            border-bottom: 3px $main solid;
                        }
                    }
                }
            }
        }
    }
</style>